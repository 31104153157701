import { Link } from '~/shared/components';
import { useFrame } from '~/shared/utils';
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { MiniBasketButton } from './components/MiniBasketButton';
import { FavoriteButton } from './components/FavoriteButton';
import {
    MenuHeaderContainer,
    StyledContent,
    StyledDesktopNav,
    StyledIconNav,
    StyledLogo,
    StyledLogoContainer,
    StyledMainNavigation,
    StyledSearchInputContainer,
    StyledSearchInputContainerMobile,
    StyledLeftContainer,
    StyledRightContainer,
    ProgressLoader,
    StyledContainerMotion,
    StyledContainerMotionFilter,
} from './styled';
import { MobileMegaMenuButton } from './components/MobileMegaMenuButton';
import { N30MegaMenu } from '~/features/navigation/components/N30MegaMenu';
import { SearchInput } from '../search/SearchInput';
import { theme } from '~/theme';
import { DesktopMetaMenuItem } from './components/DesktopMetaMenuItem';
import { UspMetaMenuItem } from './components/UspMetaMenuItem';
import { LoginButton } from './components/LoginButton';
import { Banner } from '~/shared/components/Banner';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import styled from '@emotion/styled';
import { mq } from '~/lib';

import { usePage } from '~/templates/pages/hooks/usePage';
import Flex from '~/shared/components/Flex';
import { useMenuScroll } from '~/templates/pages/hooks/useMenuScroll';
import { useBannerState } from '../../hooks/useBannerState';
import useCustomer from '~/features/commerce-api/hooks/useCustomer';
import dynamic from 'next/dynamic';
import { useSearchStore } from '~/services/search/useSearchStore';
import { Index, InstantSearch, InstantSearchSSRProvider } from 'react-instantsearch';
import { algoliaClient, getAlgoliaIndex, MainAlgoliaIndex } from '~/lib/algolia';
import { useRouter } from 'next/router';
import { useIsMobile } from '~/shared/hooks/useIsMobile/useIsMobile';
import HitsHeader from '~/features/productList/components/HitsHeader/HitsHeader';
import { routing } from '~/features/productList/routing';
import { useGetTotalHits } from '~/shared/utils/useGetTotalHits';
import FilterDrawer from '~/features/productList/components/FilterDrawer/FilterDrawer';
import FlexContent from '~/shared/components/FlexGrowContent';
import { SkipLink } from '~/templates/pages/components/DynamicLayout/components/Standard/styled';

import { SearchPlaceholderComponent } from '../search/SearchPlaceholderComponent';
import { useTranslation } from '~/shared/utils/translation';

const indexName = getAlgoliaIndex('MAIN');

type NavJustifyContent = {
    center: string;
    end: string;
    start: string;
};
type Props = {
    showTopBanner: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const SearchOverlay = dynamic(() => import('../search/SearchOverlay'), {
    ssr: false,
});

const N20MainMenuComponent = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const [visible, setVisible] = useState(true);
    const [instantSearchReady, setInstantSearchReady] = useState(false);
    const [isTop, setIsTop] = useState(true);
    let lastScrollTop = 0;
    const isMobile = useIsMobile();
    const { menuType, isAtTop } = useMenuScroll();
    const { showTopBanner } = props;
    const { totalHits } = useGetTotalHits();
    const { push } = useRouter();
    const {
        data,
        data: { settings },
    } = useFrame();
    const { isStorePersonnel } = useCustomer();
    const { showSearchPage, setShowSearch } = useSearchStore();
    const page = usePage();
    const { translate } = useTranslation();

    const { mainMenu = [] } = data?.navigation || {};
    const headerRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => headerRef.current as HTMLDivElement, [ref]);
    const justify: NavJustifyContent = {
        center: 'center',
        end: 'flex-end',
        start: 'flex-start',
    };

    const searchOverlayRef = useRef<HTMLDivElement>(null);
    const menuHeaderRef = useRef<HTMLDivElement>(null);
    const headerNavigationRef = useRef<HTMLDivElement>(null);
    const searchContainerRef = useRef<HTMLDivElement>(null);

    const { setIsBannerOpen, isBannerOpen, setHeaderHeight } = useBannerState();

    const closeBanner = () => {
        setIsBannerOpen(false);
    };

    const handleScroll = () => {
        const currentScroll = window.scrollY || document.documentElement.scrollTop;
        setVisible(currentScroll <= lastScrollTop || currentScroll < 10);
        lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;

        setIsTop(lastScrollTop <= 500);
    };

    const goToSearchQuery = (searchQuery: string) => {
        const searchPageUrl = settings?.pageReferences.searchPage?.url ?? '/';

        // Evaluate URL if it already has a slash at the beginning to prevent routing errors.
        const evaluatedUrl = searchPageUrl.charAt(0) === '/' ? searchPageUrl : `/${searchPageUrl}`;

        push(`${evaluatedUrl}?query=${searchQuery}`);

        setShowSearch(false);
    };

    const redirectToPage = (redirectUrl: string) => {
        push(redirectUrl);
        setShowSearch(false);
    };

    const handleSubmitSearch = async (searchQuery: string, redirectUrl?: string) => {
        const siteRoot = window?.location?.origin;

        if (!redirectUrl) {
            goToSearchQuery(searchQuery);
            return;
        }

        if (redirectUrl && !redirectUrl.startsWith('/')) {
            redirectToPage(redirectUrl);
            return;
        }

        try {
            const res = await fetch(`${siteRoot}${redirectUrl}`, { method: 'HEAD' });
            if (res.status === 200) {
                redirectToPage(redirectUrl);
                return;
            } else {
                goToSearchQuery(searchQuery);
                return;
            }
        } catch (error) {
            goToSearchQuery(searchQuery);
        }
    };

    const routings = useMemo(() => {
        if (!page.data?.plpResult?.serverUrl) return undefined;

        return routing(page.data?.plpResult?.serverUrl);
    }, [page.data?.plpResult?.serverUrl]);

    const serverState = useMemo(
        () => (page.data?.plpResult?.serverState ? page.data?.plpResult?.serverState : undefined),
        [page.data?.plpResult?.serverState],
    );

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (!headerRef.current) return;

        const updateHeaderHeight = () => {
            setHeaderHeight(headerRef.current?.offsetHeight || 0);
        };

        // Initial measurement
        updateHeaderHeight();

        // Update on resize
        window.addEventListener('resize', updateHeaderHeight);
        return () => window.removeEventListener('resize', updateHeaderHeight);
    }, [headerRef, setHeaderHeight]);

    useEffect(() => {
        // Create a function to check if search inputs are visible
        const checkSearchInputLoaded = () => {
            const searchInputs = document.querySelectorAll('[data-testid="search-input"]');
            if (searchInputs.length > 0) {
                setInstantSearchReady(true);
            } else {
                // Check again after a short delay
                setTimeout(checkSearchInputLoaded, 50);
            }
        };

        checkSearchInputLoaded();

        return () => {
            // Cleanup if needed
        };
    }, []);

    return useMemo(
        () => (
            <>
                <StyledMainNavigation
                    tabIndex={-1}
                    key={'mainMenu'}
                    variants={{
                        Full: {
                            y: 0,
                            transition: {
                                delayChildren: 1,
                                duration: 0.1,
                                ease: 'easeIn',
                            },
                            transitionEnd: {
                                y: 0,
                            },
                        },
                        Hidden: {
                            y: 'calc(-100% - 33px)',
                            transition: {
                                delayChildren: 1,
                                duration: 0.1,
                                ease: 'easeIn',
                            },
                        },
                        Desktop: {
                            y: 0,
                            transition: {
                                delayChildren: 1,
                                duration: 0.1,
                                ease: 'easeIn',
                            },
                        },
                    }}
                    initial={menuType}
                    animate={menuType}
                    ref={headerRef}
                    id="mainNavigation"
                >
                    <SkipLink
                        href={page?.type === 'p40ProductListPage' ? '#productList' : '#mainContent'}
                    >
                        Skip to main content
                    </SkipLink>
                    <StyledContainerMotion
                        isHiddenMobile={
                            isMobile &&
                            !isTop &&
                            (page.type === 'p40ProductListPage' || page.type === 'p10SearchPage')
                        }
                        isMobile={isMobile}
                    >
                        {showTopBanner === true && (
                            <Banner
                                textColor={('#' + data.header?.topBannerTextColor) as string}
                                backGroundColor={
                                    ('#' + data.header?.topBannerBackGroundColor) as string
                                }
                                bannerText={data.header?.topBanner as string}
                                variant="top"
                            />
                        )}
                        <StyledContent
                            css={{
                                [mq(0, 'md')]: {
                                    display: 'none',
                                },
                            }}
                            topBanner
                        >
                            {!!data.navigation?.metaMenu?.UspLinks?.length && (
                                <Banner
                                    textColor={'#' + data.header?.uspTextColor}
                                    backGroundColor={
                                        isStorePersonnel
                                            ? theme.colors.brandOrangeMedium
                                            : '#' + data.header?.uspBackgroundColor
                                    }
                                    childComponent={
                                        <>
                                            {data.navigation?.metaMenu?.UspLinks?.map((section) => {
                                                return (
                                                    <UspMetaMenuItem
                                                        section={section}
                                                        desktop
                                                        textColor={
                                                            ('#' +
                                                                data.header?.uspTextColor) as string
                                                        }
                                                        key={section.id}
                                                    />
                                                );
                                            })}
                                        </>
                                    }
                                    desktop
                                />
                            )}
                            {data.header?.campaignBannerLink && (
                                <Banner
                                    textColor={('#' + data.header?.textColor) as string}
                                    backGroundColor={
                                        (isStorePersonnel
                                            ? theme.colors.brandOrangeLight
                                            : '#' + data.header?.backGroundColor) as string
                                    }
                                    bannerText={data.header?.campaignBannerLink?.title}
                                    bannerLink={data.header?.campaignBannerLink}
                                    withCloseBanner
                                    desktop
                                    variant="campaign"
                                />
                            )}
                        </StyledContent>

                        <MenuHeaderContainer ref={menuHeaderRef}>
                            <StyledLeftContainer>
                                <StyledIconNav mobile justifyContent={justify.start}>
                                    <MobileMegaMenuButton />
                                    <LoginButton textColor={theme.colors.black} desktop metaMenu />
                                </StyledIconNav>
                                {!isMobile && (
                                    <StyledIconNav notMobile={true} justifyContent={'flex-start'}>
                                        <StyledSearchInputContainer
                                            ref={searchContainerRef}
                                            activeOverlay={showSearchPage}
                                            notMobile={true}
                                            data-testid="search-input-container"
                                        >
                                            <SearchPlaceholderComponent
                                                icon="search"
                                                className="static-search-placeholder"
                                                onClick={() => setShowSearch(true)}
                                                isVisible={!instantSearchReady}
                                            >
                                                {translate('header.search')}
                                            </SearchPlaceholderComponent>

                                            <InstantSearch
                                                searchClient={algoliaClient}
                                                indexName={indexName}
                                            >
                                                {showSearchPage && !isMobile && (
                                                    <SearchOverlay
                                                        searchOverlayRef={searchOverlayRef}
                                                        headerRef={headerRef}
                                                        menuHeaderRef={menuHeaderRef}
                                                        headerNavigationRef={headerNavigationRef}
                                                        onSubmitSearch={handleSubmitSearch}
                                                    />
                                                )}

                                                <SearchInput onSubmitSearch={handleSubmitSearch} />
                                            </InstantSearch>
                                        </StyledSearchInputContainer>
                                    </StyledIconNav>
                                )}
                            </StyledLeftContainer>

                            <FlexContent ref={searchOverlayRef} />

                            <StyledLogoContainer
                                onFocus={() => setShowSearch(false)}
                                activeOverlay={showSearchPage}
                            >
                                <Link aria-label="Imerco" href="/">
                                    <StyledLogo />
                                </Link>
                            </StyledLogoContainer>

                            <StyledRightContainer>
                                <StyledIconNav
                                    data-testid="top-icon-nav"
                                    justifyContent={justify.end}
                                >
                                    <Flex
                                        css={{
                                            [mq(0, 'md')]: {
                                                display: 'none',
                                            },
                                        }}
                                    >
                                        {data.navigation?.metaMenu?.DesktopLinks?.map((section) => {
                                            return (
                                                <DesktopMetaMenuItem
                                                    key={section.id}
                                                    section={section}
                                                    textColor={theme.colors.black}
                                                    desktop
                                                    metaMenu
                                                />
                                            );
                                        })}
                                    </Flex>
                                    <LoginButtonT textColor={theme.colors.black} desktop metaMenu />

                                    <FavoriteButton
                                        textColor={theme.colors.black}
                                        desktop
                                        metaMenu
                                    />
                                    <MiniBasketButton
                                        textColor={theme.colors.black}
                                        desktop
                                        metaMenu
                                    />
                                </StyledIconNav>
                            </StyledRightContainer>
                        </MenuHeaderContainer>

                        {isMobile && (
                            <StyledSearchInputContainerMobile
                                ref={searchContainerRef}
                                notMobile={false}
                                activeOverlay={showSearchPage}
                                data-testid="search-input-container"
                            >
                                <SearchPlaceholderComponent
                                    icon="search"
                                    className="static-search-placeholder"
                                    onClick={() => setShowSearch(true)}
                                    isVisible={!instantSearchReady}
                                >
                                    {translate('header.search')}
                                </SearchPlaceholderComponent>
                                <InstantSearch searchClient={algoliaClient} indexName={indexName}>
                                    <SearchInput onSubmitSearch={handleSubmitSearch} />

                                    {showSearchPage && (
                                        <SearchOverlay
                                            overlayTop={50}
                                            searchOverlayRef={searchOverlayRef}
                                            headerRef={headerRef}
                                            menuHeaderRef={menuHeaderRef}
                                            headerNavigationRef={headerNavigationRef}
                                            onSubmitSearch={handleSubmitSearch}
                                        />
                                    )}
                                </InstantSearch>
                            </StyledSearchInputContainerMobile>
                        )}

                        {isBannerOpen === false ||
                            (!!data.header?.campaignBannerLink && (
                                <Banner
                                    css={{
                                        [mq('md')]: {
                                            display: 'none',
                                        },
                                    }}
                                    onCloseBanner={closeBanner}
                                    textColor={'#' + data.header?.textColor}
                                    backGroundColor={'#' + data.header?.backGroundColor}
                                    bannerText={data.header?.campaignBannerLink?.title}
                                    bannerLink={data.header?.campaignBannerLink}
                                    withCloseBanner
                                    variant="campaign"
                                />
                            ))}
                    </StyledContainerMotion>
                    {isMobile ? (
                        <StyledContainerMotionFilter
                            isDisplayed={
                                !isTop &&
                                isMobile &&
                                visible &&
                                (page.type === 'p40ProductListPage' ||
                                    page.type === 'p10SearchPage')
                            }
                        >
                            <StyledSearchInputContainerMobile
                                notMobile={false}
                                activeOverlay={showSearchPage}
                                data-testid="search-input-container"
                            >
                                <SearchPlaceholderComponent
                                    icon="search"
                                    className="static-search-placeholder"
                                    onClick={() => setShowSearch(true)}
                                    isVisible={!instantSearchReady}
                                >
                                    {translate('header.search')}
                                </SearchPlaceholderComponent>
                                <InstantSearch searchClient={algoliaClient} indexName={indexName}>
                                    <SearchInput onSubmitSearch={handleSubmitSearch} />

                                    {showSearchPage &&
                                        isMobile &&
                                        !isTop &&
                                        (page.type === 'p40ProductListPage' ||
                                            page.type === 'p10SearchPage') && (
                                            <SearchOverlay
                                                overlayTop={80}
                                                searchOverlayRef={searchOverlayRef}
                                                headerRef={headerRef}
                                                menuHeaderRef={menuHeaderRef}
                                                headerNavigationRef={headerNavigationRef}
                                                onSubmitSearch={handleSubmitSearch}
                                            />
                                        )}
                                </InstantSearch>
                            </StyledSearchInputContainerMobile>

                            <InstantSearchSSRProvider {...serverState}>
                                <InstantSearch
                                    searchClient={algoliaClient}
                                    indexName={MainAlgoliaIndex}
                                    routing={routings}
                                >
                                    <FilterDrawer />
                                    <Index indexName={MainAlgoliaIndex} indexId="products">
                                        <HitsHeader totalHits={totalHits} />
                                    </Index>
                                </InstantSearch>
                            </InstantSearchSSRProvider>
                        </StyledContainerMotionFilter>
                    ) : (
                        <StyledDesktopNav ref={headerNavigationRef} data-testid={'main-menu'}>
                            {headerRef && (
                                <N30MegaMenu navigation={mainMenu} headerRef={headerRef} />
                            )}
                        </StyledDesktopNav>
                    )}

                    <ProgressLoader id="progress-loader" />
                </StyledMainNavigation>
            </>
        ),
        [
            menuType,
            isBannerOpen,
            page?.type,
            showSearchPage,
            isMobile,
            isAtTop,
            isTop,
            totalHits,
            serverState,
            routings,
            visible,
            headerRef,
        ],
    );
});

const LoginButtonT = styled(LoginButton)(() => ({
    [mq(0, 'md')]: {
        display: 'none',
    },
}));

export const N20MainMenu = withErrorBoundary(N20MainMenuComponent);
N20MainMenuComponent.displayName = 'N20MainMenuComponent';
