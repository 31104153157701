import CloseIcon from '~/icons/close.svg';

import React, { useEffect, useRef, useState, FC } from 'react';
import { StyledSearchCloseButton, StyledSearchInputDesktop } from '../N20MainMenu/styled';
import { useTranslation } from '~/shared/utils/translation';
import { useSearchStore } from '~/services/search/useSearchStore';
import { useRouter } from 'next/router';
import { useDebounce, useKeyPressEvent } from 'react-use';
import {
    useHits,
    useSearchBox,
    UseSearchBoxProps,
    useConfigure,
    useQueryRules,
} from 'react-instantsearch';
import { sanitizeStringForUrl, buildCategoryPath, findCategoryMatch } from './search-utils';
import { useFrame } from '~/shared/utils';
import { useEvents } from '~/shared/hooks/useEvents';
import type { PrimaryCategory } from './search.definitions';
import { SearchPlaceholderComponent } from './SearchPlaceholderComponent';

type ISearchBoxProps = UseSearchBoxProps & {
    onSubmitSearch: (searchQuery: string, redirectUrl?: string) => void;
};

type SearchBoxProps = {
    value: string;
    clearInput: () => void;
    onSubmitSearch: (searchQuery: string, redirectUrl?: string) => void;
    handleClose: () => void;
};

type Redirect = {
    name?: string;
    url?: string;
};

type RenderingContent = {
    redirect?: Redirect;
};

type SearchResults = {
    renderingContent?: RenderingContent;
};

const queryHook: UseSearchBoxProps['queryHook'] = (query, search) => {
    if (!query || query.length < 3) {
        return false;
    }

    search(query);
};

const SearchBox = ({ value, clearInput, onSubmitSearch, handleClose }: SearchBoxProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [query, setQuery] = useState(value);
    const { translate } = useTranslation();
    const { data } = useFrame();
    const { overlaySearchResultEvent } = useEvents(data);

    useConfigure({ hitsPerPage: 6 });
    const { hits, results } = useHits();
    const { refine, clear } = useSearchBox({
        queryHook,
    });

    const { items: customRuleData } = useQueryRules();

    useEffect(() => inputRef?.current?.focus(), []);

    useKeyPressEvent('Escape', () => {
        clear();
        handleClose();
    });

    useKeyPressEvent('Enter', () => {
        if (!query || inputRef.current !== document.activeElement) {
            return;
        }

        const formattedQuery = sanitizeStringForUrl(query);

        const redirectName = customRuleData?.[0]?.renderingContent?.redirect?.name;
        const redirectUrl = customRuleData?.[0]?.renderingContent?.redirect?.url;

        //TODO assertions will be fixed in IMC-514
        const primaryCategory = hits[0]?.__primary_category as PrimaryCategory;

        //TODO assertions will be fixed in IMC-514
        let finalUrl = (results as SearchResults)?.renderingContent?.redirect?.url || '';

        if (redirectName === 'brands') {
            finalUrl = `${redirectUrl}${formattedQuery}`;
        } else if (redirectName === 'categories' && primaryCategory) {
            const categoryMatch = findCategoryMatch(primaryCategory, query);
            if (categoryMatch) {
                const categoryPath = buildCategoryPath(categoryMatch);
                finalUrl = `${redirectUrl}${categoryPath}`;
            }
        }

        inputRef?.current?.blur();
        clear();
        onSubmitSearch(query, finalUrl);

        overlaySearchResultEvent({
            search_term: query,
            number_of_results: results?.nbHits,
            search_url: window.location.href,
        });
    });

    useDebounce(
        () => {
            refine(query);
        },
        200,
        [query],
    );

    // NOTE: For analytics tracking result event, after 1000ms have elapsed
    useDebounce(
        () => {
            setTimeout(() => {
                overlaySearchResultEvent({
                    search_term: query,
                    number_of_results: results?.nbHits,
                    search_url: window.location.href,
                });
            }, 500);
        },
        1000,
        [query],
    );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        if (!query) {
            clearInput();
            return;
        }

        setQuery(query);
    };

    return (
        <>
            <SearchPlaceholderComponent icon="arrowLeft" onClick={handleClose}>
                {!value && translate('header.search')}
            </SearchPlaceholderComponent>
            <StyledSearchInputDesktop
                aria-label="Search"
                defaultValue={value}
                onChange={handleSearch}
                withLabel={false}
                ref={inputRef}
                data-testid="search-input"
            />
            <StyledSearchCloseButton
                aria-label="Close"
                size="small"
                onClick={clearInput}
                variant="plain"
            >
                <CloseIcon />
            </StyledSearchCloseButton>
        </>
    );
};

export const SearchInput: FC<ISearchBoxProps> = (props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { query: params } = useRouter();
    const [value, setValue] = useState('');
    const { translate } = useTranslation();
    const { setShowSearch, showSearchPage } = useSearchStore();

    useKeyPressEvent('Escape', () => {
        if (inputRef.current === document.activeElement) {
            handleClose();
        }
    });

    useEffect(() => {
        if (!showSearchPage) {
            setValue('');
            setShowSearch(false);
        }
    }, [showSearchPage]);

    const handleClearInput = () => {
        setValue('');
        setTimeout(() => {
            inputRef?.current?.focus();
        });
    };

    const handleClose = () => {
        setShowSearch(false);
        setTimeout(() => {
            inputRef?.current?.blur();
        });
    };

    const handleFocus = () => {
        setShowSearch(true);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    if (value) {
        return (
            <SearchBox
                value={value}
                clearInput={handleClearInput}
                onSubmitSearch={props.onSubmitSearch}
                handleClose={handleClose}
            />
        );
    }

    return (
        <>
            <SearchPlaceholderComponent
                icon={showSearchPage ? 'arrowLeft' : 'search'}
                onClick={handleClose}
            >
                {!showSearchPage && translate('header.search')}
            </SearchPlaceholderComponent>

            <StyledSearchInputDesktop
                aria-label="Search"
                value={value}
                defaultValue={params.query}
                onFocus={handleFocus}
                onChange={handleChange}
                withLabel={false}
                ref={inputRef}
                data-testid="search-input"
            />
        </>
    );
};
